// Generated by Framer (e63d245)

import * as React from "react";
import { PageRoot, useInitialRouteComponent, lazy } from "framer";
const Screen = lazy(() => import("../screen/eMRoCFobg"));
const Screen1 = lazy(() => import("../screen/VA3cyLyjL"));
const Screen2 = lazy(() => import("../screen/j1cyAnLRB"));
const Screen3 = lazy(() => import("../screen/MDIOaU2AL"));
const Screen4 = lazy(() => import("../screen/XxluDkJzb"));

const routes = {"eMRoCFobg": {"page": Screen, "path": "eMRoCFobg"}, "VA3cyLyjL": {"page": Screen1, "path": "VA3cyLyjL"}, "j1cyAnLRB": {"page": Screen2, "path": "j1cyAnLRB"}, "MDIOaU2AL": {"page": Screen3, "path": "MDIOaU2AL"}, "XxluDkJzb": {"page": Screen4, "path": "XxluDkJzb"}}

function Prototype(): JSX.Element {
  const InitialRouteComponent = useInitialRouteComponent(routes, "eMRoCFobg")
  if (!InitialRouteComponent) return null
  return <PageRoot RootComponent={InitialRouteComponent} isWebsite={false} routeId={"eMRoCFobg"} routes={routes} />
}

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1253
 * @framerIntrinsicWidth 1197
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight fixed
 * @framerPrototype
 */
const Component: React.ComponentType<{}> = React.memo(Prototype)
export default Component;

Component.displayName = "Prototype/Assets";